import React, { useEffect } from "react";
import HeroBanner from "../../components/HeroBanner";
import "./TechnicalServices.scss";

export default function TechnicalServices() {
  const processSteps = [
    {
      phase: "Discovery Phase",
      activities:
        "Research and Analysis: Understand client's business, audience, competitors, and goals.",
    },
    {
      phase: "Planning Phase",
      activities:
        "Define Goals and Objectives: Clarify project scope, deliverables, timelines, and budget.",
    },
    {
      phase: "Design Phase",
      activities:
        "Wireframing: Create the basic layout and structure of web pages (UI/UX design).",
    },
    {
      phase: "Development Phase",
      activities:
        "Front-End Development: Convert designs into functional web pages using HTML, CSS, and JavaScript.",
    },
    {
      phase: "Testing Phase",
      activities:
        "Quality Assurance (QA): Test for bugs, errors, and usability across devices and browsers.",
    },
    {
      phase: "Launch Phase",
      activities:
        "Deployment: Prepare and launch the website on the client's hosting server.",
    },
    {
      phase: "Maintenance & Optimization",
      activities:
        "Monitoring and Updates: Regularly monitor performance, security, and traffic.",
    },
  ];

  const pricingPackages = [
    {
      name: "Basic - Add Content",
      hours: "2 hours",
      price: "$80.00",
      delivery: "3 days",
      pages: "2",
      services: "Content update (Text + 2 images or 1 video clip)",
      ecommerce: "No",
    },
    {
      name: "Standard - Add Content + Product",
      hours: "5.5 hours",
      price: "$220.00",
      delivery: "5 days",
      pages: "7",
      services:
        "Content update (Text + 4 images or 2 video clips) + Product upload",
      ecommerce: "No",
    },
    {
      name: "Premium - Add Content + Product",
      hours: "25 hours",
      price: "$1,000.00",
      delivery: "15 days",
      pages: "Up to 10",
      services:
        "Updating up to 10 pages (Text + 15 images or 5 videos) + Product upload",
      ecommerce: "Yes",
    },
  ];

  // (Optional) Side-effect to trigger animations on scroll if needed.
  useEffect(() => {
    // You could integrate a scroll library here if desired.
    // For now, we rely solely on CSS animations.
  }, []);

  return (
    <div className="our-work">
      <div className="hero-banner-container">
        <HeroBanner title="Our Technical Services" />
      </div>
      <div className="commit-section__text-container">
        <h1 className="commitment-title">
          Introducing Sinai Foundation Canada: Empowering Communities through
          Technology
        </h1>
        <div className="text-content">
          <p>
            Sinai Foundation Canada is a pioneering non‑profit organization
            dedicated to empowering communities through innovative solutions.
            Beyond our core mission of alleviating poverty and addressing
            immediate needs, we offer specialized web design and development
            services. Our tailored services cater specifically to non‑profit
            organizations, providing them with high‑quality web solutions at
            discounted rates.
          </p>
          <p>
            Additionally, Sinai Foundation Canada is committed to nurturing the
            next generation of talent. We partner with universities across
            Canada to empower students with essential skills for tomorrow’s
            workforce. Through mentorship programs and hands‑on training in web
            design and development, we equip university students with the tools
            they need to thrive in a digital economy.
          </p>
          <p>
            With a commitment to fostering digital empowerment, Sinai Foundation
            Canada leverages its expertise in web design to help non‑profits
            establish and enhance their online presence. By integrating
            user‑friendly interfaces, responsive designs, and robust
            functionalities, we ensure that our clients can effectively
            communicate their mission, engage their audience, and expand their
            impact.
          </p>
          <p>
            At Sinai Foundation Canada, we believe in the transformative power
            of technology to drive social change. Through our web design and
            development services, we support non‑profits in reaching their
            goals, amplifying their voices, and making a meaningful difference
            in communities locally and beyond. Join us in building a brighter
            future through technology and innovation.
          </p>
        </div>

        {/* Process Section */}
        <section className="process-section section--reveal">
          <h2 className="commitment-title">Our Process</h2>
          <div className="process-cards">
            {processSteps.map((step, index) => (
              <div
                className="process-card"
                key={index}
                style={{ animationDelay: `${index * 0.15}s` }}
              >
                <h3>{step.phase}</h3>
                <p>{step.activities}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Pricing Section */}
        <section className="pricing-section section--reveal">
          <h2 className="commitment-title">Pricing Packages</h2>
          <div className="pricing-cards">
            {pricingPackages.map((pkg, index) => (
              <div
                className="pricing-card"
                key={index}
                style={{ animationDelay: `${index * 0.15 + 0.3}s` }}
              >
                <h3 className="package-name">{pkg.name}</h3>
                <ul className="package-details">
                  <li>
                    <strong>Hours Estimated:</strong> {pkg.hours}
                  </li>
                  <li>
                    <strong>Price (CA$):</strong> {pkg.price}
                  </li>
                  <li>
                    <strong>Delivery Time:</strong> {pkg.delivery}
                  </li>
                  <li>
                    <strong>Pages Included:</strong> {pkg.pages}
                  </li>
                  <li>
                    <strong>Services Included:</strong> {pkg.services}
                  </li>
                  <li>
                    <strong>E‑commerce Functionality:</strong> {pkg.ecommerce}
                  </li>
                </ul>
              </div>
            ))}
          </div>
          <p className="text-content final-note">
            Note: Website creation is excluded from these packages. A discovery
            session is required to understand your business needs and product
            information before proceeding with any new website development.
          </p>
        </section>
      </div>
    </div>
  );
}
